export const ON_REGISTER = 'ON_REGISTER';
export const ON_CALL = 'ON_CALL';
export const GET_CONFIG = 'GET_CONFIG';
export const GET_CONFIG_KEYS = 'GET_CONFIG_KEYS';
export const GET_SERVICES = 'GET_SERVICES';
export const VEHICLE_FOUND = 'VEHICLE_FOUND';
export const VEHICLE_NOT_FOUND = 'VEHICLE_NOT_FOUND';
export const VEHICLE_MODEL_FOUND = 'VEHICLE_MODEL_FOUND';
export const VEHICLE_MODEL_NOT_FOUND = 'VEHICLE_MODEL_NOT_FOUND';
export const VEHICLE_COLOR_FOUND = 'VEHICLE_COLOR_FOUND';
export const VEHICLE_COLOR_NOT_FOUND = 'VEHICLE_COLOR_NOT_FOUND';
export const VEHICLE_YEAR_FOUND = 'VEHICLE_YEAR_FOUND';
export const VEHICLE_YEAR_NOT_FOUND = 'VEHICLE_YEAR_NOT_FOUND';
export const UPDATE_VEHICLE_MODEL = 'UPDATE_VEHICLE_MODEL';
export const UPDATE_VEHICLE_COLOR = 'UPDATE_VEHICLE_COLOR';
export const UPDATE_VEHICLE_YEAR = 'UPDATE_VEHICLE_YEAR';
export const VERIFICATION_CODE_SENT = 'VERIFICATION_CODE_SENT';
export const CODE_VERIFICATION = 'CODE_VERIFICATION';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
export const CREATE_ACCOUNT_PROCESS = 'CREATE_ACCOUNT_PROCESS';

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const VERIFICATION_FAILURE = 'VERIFICATION_FAILURE';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';

export const SELECTED_VEHICLE_LOCATION = 'SELECTED_VEHICLE_LOCATION';
export const SELECTED_DROPPOFF_LOCATION = 'SELECTED_DROPPOFF_LOCATION';
export const END_UP_WITH_CALL = 'END_UP_WITH_CALL';
export const DEFAULT_VEHICLE_LOCATION = 'DEFAULT_VEHICLE_LOCATION';

export const SET_LOCATION_ADDRESS = 'SET_LOCATION_ADDRESS';
export const SAVE_NOTES = 'SAVE_NOTES';
export const SELECT_DEFAULT_VEHICLE = 'SELECT_DEFAULT_VEHICLE';
export const SET_COVERAGE_CHECK = 'SET_COVERAGE_CHECK';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const VEHICLE_OTHER_LOCATION = 'VEHICLE_OTHER_LOCATION';
export const DROP_OFF_OTHER_LOCATION = 'DROP_OFF_OTHER_LOCATION';
export const NEAR_BY_DROP_OFF_LOCATIONS = 'NEAR_BY_DROP_OFF_LOCATIONS';
export const APPEND_NEAR_BY_DROP_OFF_LOCATIONS =
  'APPEND_NEAR_BY_DROP_OFF_LOCATIONS';
export const SET_AVAILABLE_PROVIDERS = 'SET_AVAILABLE_PROVIDERS';
export const GET_CANCEL_REASONS = 'GET_CANCEL_REASONS';
export const RESET_SIGNIN = 'RESET_SIGNIN';
export const RESET_REGISTRATION = 'RESET_REGISTRATION';
export const OTHER_PROBLEM_SPECIFIED = 'OTHER_PROBLEM_SPECIFIED';
export const SET_AUTHORIZE_CHECK = 'SET_AUTHORIZE_CHECK';
export const STORE_VERIFICATION_CODE = 'STORE_VERIFICATION_CODE';

export const HAS_ERROR = 'HAS_ERROR';
export const IS_LOADING = 'IS_LOADING';
export const STORE_RESPONSE = 'STORE_RESPONSE';
export const EMPTY_QA = 'EMPTY_QA';
export const SAFECO_EMPTY_QA = 'SAFECO_EMPTY_QA';
export const SELECTED_VEHICLE = 'SELECTED_VEHICLE';
export const PREVIOUS_QUESTION = 'PREVIOUS_QUESTION';
export const RESET_QUESTION_TREE = 'RESET_QUESTION_TREE';
export const PROBLEM_SPECIFIED = 'PROBLEM_SPECIFIED';
export const ON_SELECT_FINAL_SERVICE_ACTION = 'END_UP_WITH_SERVICE_ACTION';
export const RESET_CUSTOM_QUESTION_TREE = 'RESET_CUSTOM_QUESTION_TREE';
export const STORE_CUSTOM_RESPONSE = 'STORE_CUSTOM_RESPONSE';
export const END_CUSTOM_QUESTION = 'END_CUSTOM_QUESTION';
export const CUSTOM_PREVIOUS_QUESTION = 'CUSTOM_PREVIOUS_QUESTION';
export const VEHICLE_CREATED_SUCCESS = 'VEHICLE_CREATED_SUCCESS';
export const VEHICLE_CREATION_FAILURE = 'VEHICLE_CREATION_FAILURE';
export const RESET_FAULT_QUESTION_TREE = 'RESET_FAULT_QUESTION_TREE';
export const STORE_FAULT_RESPONSE = 'STORE_FAULT_RESPONSE';
export const FAULT_PREVIOUS_QUESTION = 'FAULT_PREVIOUS_QUESTION';
export const ON_SERVICE_OUTCOME = 'ON_SERVICE_OUTCOME';
export const SET_USER_DATA = 'SET_USER_DATA';
export const CONFIRM_DETAIL_SHOW = 'CONFIRM_DETAIL_SHOW';
export const VEHICLE_LOCATION_SELECTED = 'VEHICLE_LOCATION_SELECTED';
export const DROP_OFF_LOCATION_SELECTED = 'DROP_OFF_LOCATION_SELECTED';
export const SET_FLOW_ORIGIN = 'SET_FLOW_ORIGIN';
export const SET_PHONE = 'SET_PHONE';
export const SET_CALL_SUPPORT = 'SET_CALL_SUPPORT';
export const SET_MESSAGE_RECEIVED = 'SET_MESSAGE_RECEIVED';
export const SET_VIN_NUMBER = 'SET_VIN_NUMBER';
export const CALL_DROP_OFF_LOCATION = 'CALL_DROP_OFF_LOCATION';
export const CONFIRM_DETAIL_TOW_JOB = 'CONFIRM_DETAIL_TOW_JOB';
export const IS_BACK_FROM_NATIVE = 'IS_BACK_FROM_NATIVE';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_VEHICLE_DATA = 'SET_VEHICLE_DATA';
export const SET_IS_MAKE_DATA_AVAILABLE = 'SET_IS_MAKE_DATA_AVAILABLE';
export const ALREADY_ADDED_VEHICLE_SELECT = 'ALREADY_ADDED_VEHICLE_SELECT';
export const ALREADY_ADDED_VEHICLE = 'ALREADY_ADDED_VEHICLE';
export const ALREADY_ADDED_VEHICLE_NEW_VEHICLE =
  'ALREADY_ADDED_VEHICLE_NEW_VEHICLE';

export const RESET_PCUSTOM_QUESTION_TREE = 'RESET_PCUSTOM_QUESTION_TREE';
export const STORE_PCUSTOM_RESPONSE = 'STORE_PCUSTOM_RESPONSE';
export const CLEAR_PCUSTOM_RESPONSE = 'CLEAR_PCUSTOM_RESPONSE';
export const END_PCUSTOM_QUESTION = 'END_PCUSTOM_QUESTION';
export const PCUSTOM_PREVIOUS_QUESTION = 'PCUSTOM_PREVIOUS_QUESTION';
export const SET_CUSTOMER_INFO = 'SET_CUSTOMER_INFO';
export const SET_CONFIG_DATA = 'SET_CONFIG_DATA';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const IS_CURRENT_LOCATION_ALLOWED = 'IS_CURRENT_LOCATION_ALLOWED';
export const SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE';
export const SET_POLICY_EXHAUSTED = 'SET_POLICY_EXHAUSTED';
export const SET_ON_DEMAND_JOB = 'SET_ON_DEMAND_JOB';
export const SET_CAMPAIGN = 'SET_CAMPAIGN';
export const SET_AUTHORIZATION_OBJ = 'SET_AUTHORIZATION_OBJ';
export const STORE_POLICY_SEARCH_INFO = 'STORE_POLICY_SEARCH_INFO';
export const POLICY_INFO = 'POLICY_INFO';
export const CLEAR_POLICY_INFO = 'CLEAR_POLICY_INFO';
export const CLEAR_CHATBOT = 'CLEAR_CHATBOT';
export const CLEAR_CHAT_BOT_DATA = 'CLEAR_CHAT_BOT_DATA';
export const SET_IS_NATIVE_APP = 'SET_IS_NATIVE_APP';
export const EMPTY_HOME = 'EMPTY_HOME';
export const SET_OTHER_LOCATION_FOR_FLAG = 'SET_OTHER_LOCATION_FOR_FLAG';
export const STORE_FIELD_VALUE = 'STORE_FIELD_VALUE';
export const SET_PARTNER_SERVICE_TOKEN = 'SET_PARTNER_SERVICE_TOKEN';
export const SET_TOKEN_DETAILS = 'SET_TOKEN_DETAILS';

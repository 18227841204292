import appconfigs from '../configs/AppConfigs';

import AUDI_LOGO from '../assets/Images/AudiLogo_White.svg';
import BACK_CHEVRON from '../assets/Images/Back_Chevron.svg';
import MAP_PIN from '../assets/Images/pin_current_location.png';
import CAR_START from '../assets/Images/ic_car.png';
import FLAT_TYRE from '../assets/Images/ic_flat_tyre.png';
import LOCKOUT from '../assets/Images/ic_key.png';
import FUEL from '../assets/Images/ic_fuel.png';
import ENGINE from '../assets/Images/ic_engine.png';
import OVERHEAT from '../assets/Images/ic_overheat.png';
import LOCATION_LOGO from '../assets/Images/icon_location.svg';
import PROVIDE_PIN from '../assets/Images/truck.png';
import BACK_CHEVRON_DARK from '../assets/Images/Back_Chevron_Dark.svg';
import DRIVER from '../assets/Images/driver.png';
import BREAK_DOWN_TECH from '../assets/Images/Pin_Job_Tech_Large.svg';
import BREAK_DOWN_TOW from '../assets/Images/Pin_Job_Tow_Large.svg';
import KEY_TECH from '../assets/Images/Pin_Job_KeyReplacement_Large.svg';
import FUEL_TECH from '../assets/Images/Pin_Job_Fuel_Large.svg';
import LOADER_IMG from '../assets/Images/Spinner.svg';

export {
  AUDI_LOGO,
  BACK_CHEVRON,
  MAP_PIN,
  CAR_START,
  FLAT_TYRE,
  LOCKOUT,
  FUEL,
  ENGINE,
  OVERHEAT,
  LOCATION_LOGO,
  PROVIDE_PIN,
  BACK_CHEVRON_DARK,
  DRIVER,
  BREAK_DOWN_TECH,
  BREAK_DOWN_TOW,
  KEY_TECH,
  FUEL_TECH,
  LOADER_IMG,
};

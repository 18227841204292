export const ON_REGISTER = 'ON_REGISTER';
export const ON_CALL = 'ON_CALL';
export const GET_CONFIG = 'GET_CONFIG';
export const IS_LOADING = 'IS_LOADING';
export const GET_CONFIG_KEYS = 'GET_CONFIG_KEYS';
export const SET_CONFIG_DATA = 'SET_CONFIG_DATA';
export const HAS_ERROR = 'HAS_ERROR';
export const GET_SERVICES = 'GET_SERVICES';
export const VEHICLE_FOUND = 'VEHICLE_FOUND';
export const VEHICLE_NOT_FOUND = 'VEHICLE_NOT_FOUND';
export const VEHICLE_MODEL_FOUND = 'VEHICLE_MODEL_FOUND';
export const VEHICLE_MODEL_NOT_FOUND = 'VEHICLE_MODEL_NOT_FOUND';
export const VEHICLE_COLOR_FOUND = 'VEHICLE_COLOR_FOUND';
export const VEHICLE_COLOR_NOT_FOUND = 'VEHICLE_COLOR_NOT_FOUND';
export const VEHICLE_YEAR_FOUND = 'VEHICLE_YEAR_FOUND';
export const VEHICLE_YEAR_NOT_FOUND = 'VEHICLE_YEAR_NOT_FOUND';
export const UPDATE_VEHICLE_MODEL = 'UPDATE_VEHICLE_MODEL';
export const UPDATE_VEHICLE_COLOR = 'UPDATE_VEHICLE_COLOR';
export const UPDATE_VEHICLE_YEAR = 'UPDATE_VEHICLE_YEAR';
export const VERIFICATION_CODE_SENT = 'VERIFICATION_CODE_SENT';
export const CODE_VERIFICATION = 'CODE_VERIFICATION';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
export const CREATE_ACCOUNT_PROCESS = 'CREATE_ACCOUNT_PROCESS';
export const VEHICLE_CREATED_SUCCESS = 'VEHICLE_CREATED_SUCCESS';
export const VEHICLE_CREATION_FAILURE = 'VEHICLE_CREATION_FAILURE';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const VERIFICATION_FAILURE = 'VERIFICATION_FAILURE';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';

export const ON_SELECT_FINAL_SERVICE_ACTION = 'END_UP_WITH_SERVICE_ACTION';
export const SELECTED_VEHICLE_LOCATION = 'SELECTED_VEHICLE_LOCATION';
export const SELECTED_DROPPOFF_LOCATION = 'SELECTED_DROPPOFF_LOCATION';
export const END_UP_WITH_CALL = 'END_UP_WITH_CALL';
export const RESET_QUESTION_TREE = 'RESET_QUESTION_TREE';
export const DEFAULT_VEHICLE_LOCATION = 'DEFAULT_VEHICLE_LOCATION';
export const STORE_RESPONSE = 'STORE_RESPONSE';
export const EMPTY_QA = 'EMPTY_QA';
export const PROBLEM_SPECIFIED = 'PROBLEM_SPECIFIED';
export const PREVIOUS_QUESTION = 'PREVIOUS_QUESTION';
export const SET_LOCATION_ADDRESS = 'SET_LOCATION_ADDRESS';
export const SAVE_NOTES = 'SAVE_NOTES';
export const SELECT_DEFAULT_VEHICLE = 'SELECT_DEFAULT_VEHICLE';
export const SET_COVERAGE_CHECK = 'SET_COVERAGE_CHECK';
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS';
export const VEHICLE_OTHER_LOCATION = 'VEHICLE_OTHER_LOCATION';
export const DROP_OFF_OTHER_LOCATION = 'DROP_OFF_OTHER_LOCATION';
export const NEAR_BY_DROP_OFF_LOCATIONS = 'NEAR_BY_DROP_OFF_LOCATIONS';
export const SET_AVAILABLE_PROVIDERS = 'SET_AVAILABLE_PROVIDERS';
export const GET_CANCEL_REASONS = 'GET_CANCEL_REASONS';
export const RESET_SIGNIN = 'RESET_SIGNIN';
export const RESET_REGISTRATION = 'RESET_REGISTRATION';
export const OTHER_PROBLEM_SPECIFIED = 'OTHER_PROBLEM_SPECIFIED';
export const SET_AUTHORIZE_CHECK = 'SET_AUTHORIZE_CHECK';
export const STORE_VERIFICATION_CODE = 'STORE_VERIFICATION_CODE';
export const PAYMENT_INFO = 'PAYMENT_INFO';
export const AUTHORIZATION_INFO = 'AUTHORIZATION_INFO';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE';
export const DRIVER_LOCATION_UPDATE = 'DRIVER_LOCATION_UPDATE';
export const GOOGLE_ROUTE_DETAILS = 'GOOGLE_ROUTE_DETAILS';
export const ON_THE_WAY_DETAILS = 'ON_THE_WAY_DETAILS';
export const ETA_UPDATED_AT = 'ETA_UPDATED_AT';
export const RESET_LOCATION_DATA = 'RESET_LOCATION_DATA';
export const CONTACT_US_NUMBER = 'CONTACT_US_NUMBER';
export const EMPTY_HOME = 'EMPTY_HOME';
export const CLEAR_POLICY_INFO = 'CLEAR_POLICY_INFO';
export const SET_PAYMENT_PARAMS = 'SET_PAYMENT_PARAMS';
export const CLEAR_CHAT_BOT_DATA = 'CLEAR_CHAT_BOT_DATA';
export const SET_JOB_INFO = 'SET_JOB_INFO';
export const SET_PARTNER_CODE = 'SET_PARTNER_CODE';
export const SET_REUNITE_JOB_INFO = 'SET_REUNITE_JOB_INFO';
export const EXCHANGE_LOCATION = 'EXCHANGE_LOCATION';
export const SET_CASE_DETAIL = 'SET_CASE_DETAIL';

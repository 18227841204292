import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import 'whatwg-fetch';
import { Switch, Route, HashRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './style.scss';
// import appconfigs from './configs/AppConfigs';
import * as serviceWorker from './serviceWorker';
import { loadState, saveState } from './localStorage';
import throttle from 'lodash/throttle';
import configureStore from './store';
import ErrorFallback from './Sentry';
import * as dayjs from 'dayjs';
import * as buildInfo from './build-meta.json';
import Loader from './screens/CommonComponent/Loader';

var localizedFormat = require('dayjs/plugin/localizedFormat');
var utc = require('dayjs/plugin/utc');
dayjs.extend(localizedFormat);
dayjs.extend(utc);
if (process.env.REACT_APP_PARTNER == 'volvo') {
  require('./assets/Fonts/volvo/volvo-font.css');
}
let store;
if (
  process.env.REACT_APP_IS_TRACKER_APP === 'true' ||
  process.env.REACT_APP_PARTNER === 'safeco' ||
  process.env.REACT_APP_PARTNER === 'elephant' ||
  process.env.REACT_APP_PARTNER === 'grange' ||
  process.env.REACT_APP_PARTNER === 'amfam' ||
  process.env.REACT_APP_PARTNER === 'bmw' ||
  process.env.REACT_APP_PARTNER === 'ford'
) {
  const persistedState = loadState();
  if (persistedState) {
    const { home, vehicle } = persistedState;
    store = configureStore({ home, vehicle });
  } else {
    store = configureStore(persistedState);
  }
  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }, 1000)
  );
} else {
  store = configureStore({});
}
// APIConfigs.configureClient(appconfigs);
console.log('build info', buildInfo.default);
if (process.env.REACT_APP_BUILD == 'prod') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_BUILD,
    ignoreUrls: [
      'https://maps.googleapis.com/maps-api-v3/api/js/35/10a/poly.js',
    ],
  });
}

if (process.env.REACT_APP_PARTNER == 'volvo') {
  document.body.classList.add('volvo-app');
} else if (
  process.env.REACT_APP_PARTNER === 'safeco' ||
  process.env.REACT_APP_PARTNER === 'elephant' ||
  process.env.REACT_APP_PARTNER === 'amfam' ||
  process.env.REACT_APP_PARTNER === 'bmw' ||
  process.env.REACT_APP_PARTNER === 'ford'
) {
  document.body.classList.add('safeco-app');
  if (process.env.REACT_APP_PARTNER === 'bmw') {
    document.body.classList.add('dark-modal');
  }
} else {
  document.body.classList.add('rsa-app');
}
// if (process.env.REACT_APP_IS_TRACKER_APP === 'true') {
if (window.location.hash.includes('langCode')) {
  let langCode;
  let queryParameter = JSON.parse(
    '{"' +
      decodeURI(window.location.hash)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
  if (Object.keys(queryParameter).length === 1) {
    const firstIndex = Object.keys(queryParameter)[0];
    langCode = queryParameter[firstIndex];
  } else {
    langCode = queryParameter.langCode;
  }
  localStorage.setItem('language', langCode);
} else if (!localStorage.getItem('language')) {
  localStorage.setItem('language', 'en');
}

// }

const TrackerApp = React.lazy(() => import('./TrackerApp'));
const CustomerApp = React.lazy(() => import('./CustomerApp'));
const translate = React.lazy(() => import('./shared/Localization/translate'));
//NOTE: DO NOT REMOVE/SHIFT TO TOP "TRANSLATE" FROM "LAZY LOAD" IT WILL CREATE ISSUE
ReactDOM.render(
  <Sentry.ErrorBoundary fallback={ErrorFallback}>
    <HashRouter>
      <Provider store={store}>
        <Suspense
          fallback={
            <Loader color="#000" loaded={false} title={translate.RETRIEVING} />
          }
        >
          {process.env.REACT_APP_IS_TRACKER_APP === 'true' ? (
            <TrackerApp />
          ) : (
            <CustomerApp />
          )}
        </Suspense>
      </Provider>
    </HashRouter>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

serviceWorker.unregister();

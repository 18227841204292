export const Listener = {
  PubnubJobMessageReceived: 'PubnubJobMessageReceived',
  JobCreated: 'JobCreated',
  JobAssigned: 'JobAssigned',
  NoProviderFound: 'NoProviderFound',
  JobCancelledByProvider: 'JobCancelledByProvider',
  JobCancelledByCustomer: 'JobCancelledCustomer',
  JobComplete: 'JobComplete',
  JobPaymentFailed: 'JobPaymentFailed',
  ProviderOnTheWay: 'ProviderOnTheWay',
  ETAchanged: 'ETAchanged',
};

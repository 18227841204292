import * as types from '../../actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
  flowOrigin: '',
  callbackName: '',
  smsUpdate: null,
  callbackNumber: '',
  questions: [],
  answers: [],
  c_questions: [],
  c_answers: [],
  cp_questions: [],
  cp_answers: [],
  f_questions: [],
  f_answers: [],
  hasError: false,
  isLoading: false,
  finalAnswer: false,
  finalAnswerForCustom: false,
  finalAnswerForPCustom: false,
  finalServiceAction: false,
  vehicleInfo: {},
  isVehicleOtherLocation: false,
  isDropOffOtherLocation: false,
  vehicleLocation: null,
  dropOffLocation: null,
  nearbyDropoffLocations: [],
  memberId: null,
  accountId: null,
  confirmDetailShow: false,
  vehicleLocationSelected: false,
  dropOffLocationSelected: false,
  phone: null,
  callSupport: null,
  messageReceived: false,
  callDropOffLocation: false,
  confirmDetailTowJob: false,
  back: 0,
  alreadyAddedVehicle: false,
  alreadyAddedVehicleSelect: false,
  alreadyAddedVehicleNewVehicle: false,
  authToken: null,
  vehicleCurrentLocation: null,
  isMake: null,
  customerInfo: {},
  configData: {},
  refreshToken: {},
  isCurrentLocationAllowed: true,
  customerType: null,
  isPolicyExhausted: false,
  isOnDemandJob: false,
  campaign: null,
  refreshObj: null,
  policyValue: null,
  policyInfo: null,
  selectedVehicle: null,
  isNative: false,
  otherLocationFor: '',
  serviceToken: null,
  reuniteTowRequired: false,
  formType: '',
  tokenDetails: null,
  benefits:null
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.HAS_ERROR: {
      return {
        ...state,
        hasError: action.hasError,
      };
    }
    case types.POLICY_INFO: {
      return {
        ...state,
        policyInfo: action.value,
      };
    }
    case types.IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case types.EMPTY_QA: {
      return {
        ...initialState,
        serviceToken: state.serviceToken,
        callbackNumber: state.callbackNumber,
        formType:state.formType
      };
    }
    case types.SAFECO_EMPTY_QA: {
      return {
        ...initialState,
        policyInfo: state.policyInfo,
        policyValue: state.policyValue,
        selectedVehicle: state.selectedVehicle,
        callSupport: state.callSupport,
        serviceToken: state.serviceToken,
        callbackNumber: state.callbackNumber,
      };
    }
    case types.SELECTED_VEHICLE: {
      return {
        ...state,
        selectedVehicle: action.vehicleInfo,
      };
    }
    case types.PREVIOUS_QUESTION: {
      state.questions.splice(action.questionIndex + 1, state.questions.length);
      state.answers.splice(action.questionIndex, state.answers.length);
      state.c_questions.splice(1, state.c_questions.length);
      return {
        ...state,
        questions: state.questions,
        answers: state.answers,
        c_answers: [],
        f_questions: [],
        f_answers: [],
        finalAnswerForCustom: false,
        confirmDetailShow: false,
        finalServiceAction: false,
        finalAnswer: false,
      };
    }
    case types.STORE_RESPONSE: {
      return {
        ...state,
        answers: [...state.answers, action.response],
      };
    }
    case types.STORE_FAULT_RESPONSE: {
      return {
        ...state,
        f_answers: [...state.f_answers, action.response],
      };
    }
    case types.STORE_CUSTOM_RESPONSE: {
      return {
        ...state,
        c_answers: [...state.c_answers, action.response],
      };
    }
    case types.RESET_QUESTION_TREE: {
      if (
        state.questions.length > 0 &&
        action.response &&
        state.questions[state.questions.length - 1].questionId !=
          action.response.questionId
      ) {
        return {
          ...state,
          questions: [...state.questions, action.response],
        };
      } else {
        if (state.questions.length == 0) {
          return {
            ...state,
            questions: [...state.questions, action.response],
          };
        } else {
          return {
            ...state,
            questions: [...state.questions],
          };
        }
      }
    }
    case types.RESET_CUSTOM_QUESTION_TREE: {
      if (
        state.c_questions.length > 0 &&
        action.response &&
        state.c_questions[state.c_questions.length - 1].questionId !=
          action.response.questionId
      ) {
        return {
          ...state,
          c_questions: [...state.c_questions, action.response],
        };
      } else {
        if (state.c_questions.length == 0) {
          return {
            ...state,
            c_questions: [...state.c_questions, action.response],
          };
        } else {
          return {
            ...state,
            c_questions: [...state.c_questions],
          };
        }
      }
    }
    case types.RESET_FAULT_QUESTION_TREE: {
      if (
        state.f_questions.length > 0 &&
        action.response &&
        state.f_questions[state.f_questions.length - 1].nodeId !=
          action.response.nodeId
      ) {
        return {
          ...state,
          f_questions: [...state.f_questions, action.response],
          cp_answers: [],
        };
      } else {
        if (state.f_questions.length == 0) {
          return {
            ...state,
            f_questions: [...state.f_questions, action.response],
            cp_answers: [],
          };
        } else {
          return {
            ...state,
            f_questions: [...state.f_questions],
            cp_answers: [],
          };
        }
      }
    }
    case types.ON_SELECT_FINAL_SERVICE_ACTION: {
      return {
        ...state,
        finalAnswer: action.response,
      };
    }
    case types.END_CUSTOM_QUESTION: {
      return {
        ...state,
        finalAnswerForCustom: action.response,
      };
    }
    case types.CUSTOM_PREVIOUS_QUESTION: {
      state.c_questions.splice(action.questionIndex, state.c_questions.length);
      state.c_answers.splice(action.questionIndex, state.c_answers.length);
      return {
        ...state,
        c_questions: state.c_questions,
        c_answers: state.c_answers,
        finalAnswerForCustom: false,
        confirmDetailShow: false,
        f_questions: [],
        f_answers: [],
        finalServiceAction: false,
      };
    }
    case types.FAULT_PREVIOUS_QUESTION: {
      state.f_questions.splice(action.questionIndex, state.f_questions.length);
      state.f_answers.splice(action.questionIndex, state.f_answers.length);
      return {
        ...state,
        f_questions: state.f_questions,
        f_answers: state.f_answers,
        finalServiceAction: false,
        confirmDetailShow: false,
      };
    }
    case types.VEHICLE_CREATED_SUCCESS: {
      return {
        ...state,
        vehicleInfo: action.response,
      };
    }
    case types.CLEAR_CHATBOT: {
      return {
        ...state,
        finalServiceAction: false,
      };
    }
    case types.ON_SERVICE_OUTCOME: {
      return {
        ...state,
        finalServiceAction: action.response,
      };
    }
    case types.SELECTED_VEHICLE_LOCATION:
      return {
        ...state,
        vehicleLocation: action.payload,
      };
    case types.SELECTED_DROPPOFF_LOCATION:
      return {
        ...state,
        dropOffLocation: action.payload,
      };
    case types.VEHICLE_OTHER_LOCATION: {
      return {
        ...state,
        isVehicleOtherLocation: action.value,
      };
    }
    case types.DROP_OFF_OTHER_LOCATION: {
      return {
        ...state,
        isDropOffOtherLocation: action.value,
      };
    }
    case types.NEAR_BY_DROP_OFF_LOCATIONS: {
      return {
        ...state,
        nearbyDropoffLocations: action.response,
      };
    }
    case types.APPEND_NEAR_BY_DROP_OFF_LOCATIONS: {
      return {
        ...state,
        nearbyDropoffLocations: [
          ...state.nearbyDropoffLocations,
          ...action.response,
        ],
      };
    }
    case types.SET_USER_DATA: {
      return {
        ...state,
        memberId: action.memberId,
        accountId: action.accountId,
        securityTokenExpiration: action.tokenExpiration,
      };
    }
    case types.CONFIRM_DETAIL_SHOW: {
      return {
        ...state,
        confirmDetailShow: action.response,
      };
    }
    case types.VEHICLE_LOCATION_SELECTED: {
      return {
        ...state,
        vehicleLocationSelected: action.response,
      };
    }
    case types.DROP_OFF_LOCATION_SELECTED: {
      return {
        ...state,
        dropOffLocationSelected: action.response,
      };
    }
    case types.SET_FLOW_ORIGIN: {
      return {
        ...state,
        flowOrigin: action.response,
      };
    }
    case types.SET_PHONE: {
      return {
        ...state,
        phone: action.response,
      };
    }
    case types.SET_CALL_SUPPORT: {
      return {
        ...state,
        callSupport: action.response,
      };
    }
    case types.SET_MESSAGE_RECEIVED: {
      return {
        ...state,
        messageReceived: action.response,
      };
    }
    case types.SET_CAMPAIGN: {
      return {
        ...state,
        campaign: action.response,
      };
    }
    case types.SET_VIN_NUMBER: {
      return {
        ...state,
        vin: action.response,
      };
    }
    case types.SET_CUSTOMER_TYPE: {
      return {
        ...state,
        customerType: action.response,
      };
    }
    case types.SET_POLICY_EXHAUSTED: {
      return {
        ...state,
        isPolicyExhausted: action.response,
      };
    }
    case types.SET_ON_DEMAND_JOB: {
      return {
        ...state,
        isOnDemandJob: action.response,
      };
    }
    case types.CALL_DROP_OFF_LOCATION: {
      return {
        ...state,
        callDropOffLocation: action.response,
      };
    }
    case types.CONFIRM_DETAIL_TOW_JOB: {
      return {
        ...state,
        confirmDetailTowJob: action.response,
      };
    }
    case types.IS_BACK_FROM_NATIVE: {
      return {
        ...state,
        back: action.response,
      };
    }
    case types.ALREADY_ADDED_VEHICLE: {
      return {
        ...state,
        alreadyAddedVehicle: action.response,
      };
    }
    case types.ALREADY_ADDED_VEHICLE_SELECT: {
      return {
        ...state,
        alreadyAddedVehicleSelect: action.response,
      };
    }
    case types.ALREADY_ADDED_VEHICLE_NEW_VEHICLE: {
      return {
        ...state,
        alreadyAddedVehicleNewVehicle: action.response,
      };
    }
    case types.SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: action.response,
      };
    }
    case types.SET_CONFIG_DATA: {
      return {
        ...state,
        configData: action.response,
      };
    }
    case types.SET_CUSTOMER_INFO: {
      return {
        ...state,
        customerInfo: action.response,
      };
    }
    case types.SET_CURRENT_LOCATION: {
      return {
        ...state,
        vehicleCurrentLocation: action.response,
      };
    }
    case types.SET_IS_MAKE_DATA_AVAILABLE: {
      return {
        ...state,
        isMake: action.response,
      };
    }
    case types.PCUSTOM_PREVIOUS_QUESTION: {
      state.cp_questions.splice(
        action.questionIndex,
        state.cp_questions.length
      );
      state.cp_answers.splice(action.questionIndex, state.cp_answers.length);
      return {
        ...state,
        cp_questions: state.cp_questions,
        cp_answers: state.cp_answers,
        finalAnswerForPCustom: false,
        confirmDetailShow: false,
      };
    }
    case types.RESET_PCUSTOM_QUESTION_TREE: {
      if (
        state.cp_questions.length > 0 &&
        action.response &&
        state.cp_questions[state.cp_questions.length - 1].questionId !=
          action.response.questionId
      ) {
        return {
          ...state,
          cp_questions: [...state.cp_questions, action.response],
        };
      } else {
        if (state.cp_questions.length == 0) {
          return {
            ...state,
            cp_questions: [...state.cp_questions, action.response],
          };
        } else {
          return {
            ...state,
            cp_questions: [...state.cp_questions],
          };
        }
      }
    }
    case types.STORE_PCUSTOM_RESPONSE: {
      return {
        ...state,
        cp_answers: [...state.cp_answers, action.response],
      };
    }
    case types.CLEAR_PCUSTOM_RESPONSE: {
      return {
        ...state,
        cp_answers: [],
      };
    }
    case types.END_PCUSTOM_QUESTION: {
      return {
        ...state,
        finalAnswerForPCustom: action.response,
      };
    }
    case types.SET_REFRESH_TOKEN: {
      return {
        ...state,
        refreshToken: action.refreshToken,
      };
    }
    case types.IS_CURRENT_LOCATION_ALLOWED: {
      return {
        ...state,
        isCurrentLocationAllowed: action.allowed,
      };
    }
    case types.SET_AUTHORIZATION_OBJ: {
      return {
        ...state,
        refreshObj: action.refreshObj,
      };
    }
    case types.STORE_POLICY_SEARCH_INFO: {
      return {
        ...state,
        policyValue: action.searchData,
      };
    }
    case types.CLEAR_POLICY_INFO: {
      return {
        ...state,
        policyValue: null,
        policyInfo: null,
        vin: null,
        serviceToken: null,
        callbackNumber: null
      };
    }
    case types.CLEAR_CHAT_BOT_DATA: {
      return {
        ...initialState,
        accountId: state.accountId,
        configData: state.configData,
        authToken: state.authToken,
        customerInfo: state.customerInfo,
        customerType: state.customerType,
        phone: state.phone,
        refreshToken: state.refreshToken,
        memberId: state.memberId,
        securityTokenExpiration: state.securityTokenExpiration,
        vin: state.vin,
        isNative: state.isNative,
      };
    }
    case types.SET_IS_NATIVE_APP: {
      return {
        ...state,
        isNative: action.data,
      };
    }
    case types.SET_OTHER_LOCATION_FOR_FLAG: {
      return {
        ...state,
        otherLocationFor: action.data,
      };
    }
    case types.STORE_FIELD_VALUE: {
      return {
        ...state,
        ...action.data
      };
    }

    case types.SET_PARTNER_SERVICE_TOKEN: {
      return {
        ...state,
        serviceToken: action.data,
      };
    }
    case types.SET_TOKEN_DETAILS: {
      return {
        ...state,
        tokenDetails: action.response,
      };
    }
    default:
      return state;
  }
}

import APIConfigs from '../configs/AppConfigs';
import { VehicleAPI } from '../shared/NetworkLayer/UrgentlyAPIs/APISignature';
import { UrgentlyAPIs } from '../shared';

export const configCall = () => {
  VehicleAPI.getSystemConfig.setParam(
    APIConfigs.ENVIROMENTUL,
    APIConfigs.API_VERSION
  );
  if (process.env.REACT_APP_PARTNER === 'safeco') {
    VehicleAPI.getSystemConfig.setPartnerCode('lm');
  } else if (process.env.REACT_APP_PARTNER === 'elephant') {
    VehicleAPI.getSystemConfig.setPartnerCode('elephant.insurance');
  } else if (process.env.REACT_APP_PARTNER === 'amfam') {
    VehicleAPI.getSystemConfig.setPartnerCode('amfam.insurance');
  } else if (process.env.REACT_APP_PARTNER === 'bmw') {
    VehicleAPI.getSystemConfig.setPartnerCode('bmw.tr');
  } else {
    VehicleAPI.getSystemConfig.setPartnerCode(process.env.REACT_APP_PARTNER);
  }
  let rqURL = VehicleAPI.getSystemConfig.getRQURL;
  // this.props.actions.isLoading(true);
  return UrgentlyAPIs.GET(rqURL, false)
    .then((response) => {
      // this.props.actions.isLoading(false);
      if (response && response.config) {
        return response.config['support.call.phone'];
        // this.setState({
        //   callUsNumber: phone
        // });
        // this.props.actions.onSetSupportNumber(phone);
      }
    })
    .catch((error) => {
      throw error;
      // this.notifyError(this.configCall);
    });
};

export const numericOnly = (value) => {
  return value.trim().replace(/([^0-9])/g, '');
}
export const trimAfterMaxLength = (value,maxLength) => {
  return value.replace(/\s+/g, ' ').substring(0, maxLength);
}
export const getParameterByName= function(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}